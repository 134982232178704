import React, { Component } from 'react';
import Frame from "../../components/frame";
import {withRouter} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import FT_Matic from "../../contracts/FT_Matic.json";
import getWeb3 from "../../getWeb3";
const defaultAmountArr = ['100','200','500','1000','2000'];

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      web3: null,
      accounts: null,
      contract: null,
      contractAddress: null,
      sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
      userInfo:{},
      TotalBonus:0,
      totalWithdrawn:0,
      totalDeposits:0,
      totalInvested:0,
      totalUsers:0,
      networkMain:false,
      YourAddress: '-',
      YourAddressfull: null,
      amountBNB:null,
      user_referer:'-----------',
      balance:null,
      adminFee:null,
      adminFee_percentage:5,
      reinvest_percentage:0,
      withdrwal_percentage:0,
      //GetDownlineIncomeByUserId:0,
      //GetUplineIncomeByUserId:0,
      communityLevels:[],
      sponsorList:[],
      poolDepositTotal:0,
      poolDeposit:0,
      poolQualifierCount:0,
      GetPoolIncome:0,
      GetPoolIncomeTwo:0,
      _userUplineIncome:0,
      _userDownlineIncome:0,
      poolDepositTotalTwo:0,
      poolDepositTwo:0,
      poolQualifierCountTwo:0,
    }
  }

  componentDidMount = async () => {
    try {
      // Get network provider and web3 instance.
      const web3 = await getWeb3();

      // Use web3 to get the user's accounts.
      setInterval(async()=>{
        const accounts = await web3.eth.getAccounts();
       
        if(this.state.accounts !== accounts[0]){
        // Get the contract instance.
        const networkId = await web3.eth.net.getId();
        //alert(networkId);
        //if(networkId !== 56) throw "Please connect Mainnet"; 
        const deployedNetwork = FT_Matic.networks[networkId];
        const instance = new web3.eth.Contract(
          FT_Matic.abi,
          deployedNetwork && deployedNetwork.address,
        );   
        
        this.setState({ web3, accounts:accounts[0], contract: instance, contractAddress:deployedNetwork.address}, this.fetchData);
        }
     },1000);

    } catch (error) {
      // Catch any errors for any of the above operations.
      // alert(
      //   error
      // );
      this.setState({networkMain:false});
      console.error(error);
    }
  };

  fetchData = async() => {
    const { accounts, contract } = this.state;

    console.log(this.state.contractAddress);

    let YourAddress = accounts.slice(0, 10) + '.....' + accounts.slice(accounts.length-5, accounts.length);
    let YourAddressfull = accounts;
    let userInfo = await contract.methods.users(accounts).call();    
    userInfo.amount = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
    userInfo.amount = parseFloat(userInfo.amount).toFixed(5);
    userInfo.referrerBonus = this.state.web3.utils.fromWei(userInfo.referrerBonus, 'ether');
    userInfo.referrerBonus = parseFloat(userInfo.referrerBonus).toFixed(5);
    userInfo.totalWithdrawn = this.state.web3.utils.fromWei(userInfo.totalWithdrawn, 'ether');
    userInfo.totalWithdrawn = parseFloat(userInfo.totalWithdrawn).toFixed(5);
    let user_referer = '---------';
    if(userInfo.checkpoint != undefined && userInfo.checkpoint > 0){
      user_referer = userInfo.referrer;
      user_referer = user_referer.slice(0, 10) + '.....' + user_referer.slice(user_referer.length-5, user_referer.length);
    }

    let GetPoolIncome = await contract.methods.GetPoolIncome(accounts).call();
    GetPoolIncome = this.state.web3.utils.fromWei(GetPoolIncome[0], 'ether');
    GetPoolIncome = parseFloat(GetPoolIncome).toFixed(5);

    let GetPoolIncomeTwo = await contract.methods.GetPoolIncomeTwo(accounts).call();
    GetPoolIncomeTwo = this.state.web3.utils.fromWei(GetPoolIncomeTwo[0], 'ether');
    GetPoolIncomeTwo = parseFloat(GetPoolIncomeTwo).toFixed(5);
    
    let TotalBonus = await contract.methods.TotalBonus(accounts).call();
    TotalBonus = this.state.web3.utils.fromWei(TotalBonus, 'ether');
    TotalBonus = parseFloat(TotalBonus).toFixed(5);
    let totalWithdrawn = await contract.methods.totalWithdrawn().call();
    totalWithdrawn = this.state.web3.utils.fromWei(totalWithdrawn, 'ether');
    totalWithdrawn = parseFloat(totalWithdrawn).toFixed(5);
    let totalDeposits = await contract.methods.totalDeposits().call();
    totalDeposits = parseFloat(totalDeposits).toFixed(5);
    let totalInvested = await contract.methods.totalInvested().call();
    totalInvested = this.state.web3.utils.fromWei(totalInvested, 'ether');
    totalInvested = parseFloat(totalInvested).toFixed(5);
    let totalUsers = await contract.methods.totalUsers().call();

    let poolDepositTotal = await contract.methods.poolDepositTotal().call();
    poolDepositTotal = this.state.web3.utils.fromWei(poolDepositTotal, 'ether');
    poolDepositTotal = parseFloat(poolDepositTotal).toFixed(5);

    let poolDepositTotalTwo = await contract.methods.poolDepositTotalTwo().call();
    poolDepositTotalTwo = this.state.web3.utils.fromWei(poolDepositTotalTwo, 'ether');
    poolDepositTotalTwo = parseFloat(poolDepositTotalTwo).toFixed(5);

    let poolDeposit = await contract.methods.poolDeposit().call();
    poolDeposit = this.state.web3.utils.fromWei(poolDeposit, 'ether');
    poolDeposit = parseFloat(poolDeposit).toFixed(5);

    let poolDepositTwo = await contract.methods.poolDepositTwo().call();
    poolDepositTwo = this.state.web3.utils.fromWei(poolDepositTwo, 'ether');
    poolDepositTwo = parseFloat(poolDepositTwo).toFixed(5);

    let poolQualifierCount = await contract.methods.poolQualifierCount().call();
    let poolQualifierCountTwo = await contract.methods.poolQualifierTwoCount().call();
    

    let balance = TotalBonus;
    let adminFee = balance*this.state.adminFee_percentage/100;
    balance = balance - adminFee;


    let getEligibleWithdrawal = await contract.methods.getEligibleWithdrawal(accounts).call();
    let reinvest_percentage = getEligibleWithdrawal.reivest;
    let withdrwal_percentage = getEligibleWithdrawal.withdrwal;

    let singleUplineBonusTaken = this.state.web3.utils.fromWei(userInfo.singleUplineBonusTaken, 'ether');
    let singleDownlineBonusTaken = this.state.web3.utils.fromWei(userInfo.singleDownlineBonusTaken, 'ether');

    // let GetDownlineIncomeByUserId = await contract.methods.GetDownlineIncomeByUserId(accounts).call();
    // GetDownlineIncomeByUserId = this.state.web3.utils.fromWei(GetDownlineIncomeByUserId, 'ether');
    // GetDownlineIncomeByUserId = GetDownlineIncomeByUserId - singleDownlineBonusTaken;
    // GetDownlineIncomeByUserId = parseFloat(GetDownlineIncomeByUserId).toFixed(5);

    // let GetUplineIncomeByUserId = await contract.methods.GetUplineIncomeByUserId(accounts).call();
    // GetUplineIncomeByUserId = this.state.web3.utils.fromWei(GetUplineIncomeByUserId, 'ether');
    // GetUplineIncomeByUserId = GetUplineIncomeByUserId - singleUplineBonusTaken;
    // GetUplineIncomeByUserId = parseFloat(GetUplineIncomeByUserId).toFixed(5);

    let _userUplineIncome = await contract.methods._userUplineIncome(accounts).call();
    _userUplineIncome = this.state.web3.utils.fromWei(_userUplineIncome, 'ether');
    _userUplineIncome = _userUplineIncome - singleUplineBonusTaken;
    _userUplineIncome = parseFloat(_userUplineIncome).toFixed(5);

    let singleDownlineBonus = this.state.web3.utils.fromWei(userInfo.singleDownlineBonus, 'ether');
    let _userDownlineIncome = singleDownlineBonus - singleDownlineBonusTaken;
    _userDownlineIncome = parseFloat(_userDownlineIncome).toFixed(5);

     
    this.setState({ 
      userInfo,
      TotalBonus,
      totalWithdrawn,
      totalDeposits,
      totalInvested,
      totalUsers,
      poolDepositTotal,
      poolDepositTotalTwo,
      poolDeposit,
      poolDepositTwo,
      poolQualifierCount,
      poolQualifierCountTwo,
      GetPoolIncome,
      GetPoolIncomeTwo,
      //networkMain:true,
      YourAddress:YourAddress,
      YourAddressfull:YourAddressfull,
      user_referer:user_referer,
      balance,
      adminFee,
      reinvest_percentage,
      withdrwal_percentage,
      //GetUplineIncomeByUserId,
      //GetDownlineIncomeByUserId,
      _userUplineIncome,
      _userDownlineIncome,
      loading:false
    },async()=>{
      const { accounts, contract } = this.state;
      // let getEligibleLevelCountForUpline = await contract.methods.getEligibleLevelCountForUpline(accounts).call();
      let uplineCount = 30;//getEligibleLevelCountForUpline.uplineCount;
      let downlineCount = 20;//getEligibleLevelCountForUpline.downlineCount;
      let communityLevels = [];
      let upline_users = [];
      let downline_users = [];
      let current_user = accounts;
      let userInfo = await contract.methods.users(current_user).call();
      for(let i=1;i<=uplineCount;i++){          
        if(current_user == userInfo.singleUpline) continue;
        current_user = userInfo.singleUpline;
        let emptyAddress = /^0x0+$/.test(current_user);
        if(emptyAddress) continue;
        userInfo = await contract.methods.users(current_user).call();
        let investment = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
        let income = (investment/100).toFixed(5);
        investment = parseFloat(investment).toFixed(5);
        upline_users.push({level:i,levelText:'Upline-'+i,type:'upline',username:current_user,investment:investment,income:income});
      }
      upline_users.sort((a, b) => b.level>a.level? 1 : -1);
      upline_users.map(function(val, index){
        communityLevels.push(val);
      });
      let currentUserInfo = await contract.methods.users(accounts).call();
      let investment = this.state.web3.utils.fromWei(currentUserInfo.amount, 'ether');
      let income = (investment/100).toFixed(5);
      income = (0).toFixed(5);
      investment = parseFloat(investment).toFixed(5);
      let main_user = {level:0,levelText:'You',type:'main_user',username:accounts,investment:investment,income:income};
      communityLevels.push(main_user);
      current_user = accounts;
      userInfo = await contract.methods.users(current_user).call();
      for(let i=1;i<=downlineCount;i++){
        if(current_user == userInfo.singleDownline) continue;
        current_user = userInfo.singleDownline;
        let emptyAddress = /^0x0+$/.test(current_user);
        if(emptyAddress) continue;
        userInfo = await contract.methods.users(current_user).call();
        let investment = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
        let income = (investment/100).toFixed(5);
        investment = parseFloat(investment).toFixed(5);
        downline_users.push({level:i,levelText:'Downline-'+i,type:'downline',username:current_user,investment:investment,income:income});
      }
      downline_users.map(function(val, index){
        communityLevels.push(val);
      });

      let sponsorList = [];
      let count = 0;
      for(let i=0;i<11;i++){
        let referral_stage = await contract.methods.referral_stage(accounts,i).call();
        let _investment = this.state.web3.utils.fromWei(referral_stage._investment, 'ether');
        //let ref_bonuses = await contract.methods.ref_bonuses(i).call();
        let percentage_amount = this.state.web3.utils.fromWei(referral_stage._bonus, 'ether'); //(_investment*ref_bonuses/100).toFixed(5);
        _investment = parseFloat(_investment).toFixed(5);
        percentage_amount = parseFloat(percentage_amount).toFixed(5);
        let _noOfUser = referral_stage._noOfUser;
        
        if(i == 0){
          count = _noOfUser;
        }
        let status = '';
        let requireText = '';
        if((i>=2 && i<=6) && count<6){
          status = 'Unqualified';
          requireText = 'require '+4 + ' direct';
        }else
        if((i>6 && i<=11) && count<8){
          status = 'Unqualified';
          requireText = 'require '+8 + ' direct';
        }
        sponsorList.push({requireText:requireText,status:status,level:i+1,_investment:_investment,_noOfUser:_noOfUser,percentage_amount:percentage_amount});
      }
      
      //this.setState({sponsorList});
      this.setState({communityLevels, sponsorList});
      // console.log(communityLevels);      
      // console.log(sponsorList);       
    });     
  }

  doJoinNow = async () => {
    //const weiValue = this.state.web3.utils.toWei('1', 'ether');
    //const etherValue = this.state.web3.utils.fromWei('1000000000000000000', 'ether');
    const { accounts, contract } = this.state;
    let sponsor_address = this.state.sponsor_address;
    let userInfo = await contract.methods.users(accounts).call();
    if(userInfo.checkpoint != undefined && userInfo.checkpoint > 0){
      sponsor_address = userInfo.referrer;
    }
    else if(!sponsor_address){
      this.props.enqueueSnackbar("Sponsor Address is required!",{ variant: 'error' })
      this.setState({sponsor_addressError:true});
      return false;
    }

    if(!this.state.amountBNB){
      this.props.enqueueSnackbar("Amount is required!",{ variant: 'error' })
      this.setState({amountError:true});
      return false;
    }
    
    let balance = await this.state.web3.eth.getBalance(this.state.accounts);
    let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
    let amountBNB = this.state.amountBNB;
    if(sponsor_address){
      if(balanceEthVal >= amountBNB){
        try {
          this.setState({loading:true});
          this.state.contract.events.NewDeposit((err, eventResult) => {
            console.log('eventResult',eventResult);
            if (!err) {
              let uerAddress = this.state.accounts;
              if(eventResult.returnValues[0] == uerAddress){
                this.fetchData();
                this.setState({loading:false});
                this.props.enqueueSnackbar("Joined Successfully!",{ variant: 'success' });                
              }              
            }else{
              console.log(err);
              this.setState({loading:false});
              this.props.enqueueSnackbar('Some Network Error Occurred!',{ variant: 'error' });              
            }
          });

          let weiValue = this.state.web3.utils.toWei(amountBNB, 'ether');
          let invest = await this.state.contract.methods.invest(sponsor_address).send(
            {
              from: this.state.accounts,
              value:weiValue
            }
          );
          //console.log(invest);
        }
        catch(err) {
          this.setState({loading:false});
          if (err.message.includes("User denied transaction signature")) {
            // handle the "error" as a rejection
            this.props.enqueueSnackbar(err.message,{ variant: 'error' });
          }else{
            this.props.enqueueSnackbar(err,{ variant: 'error' });
          }          
        }          
      }else{
        this.setState({loading:false});
        this.props.enqueueSnackbar("Insufficient Balance!",{ variant: 'error' });          
      }        
    }else{
      this.setState({loading:false});
      this.props.enqueueSnackbar("Please enter sponsor address!",{ variant: 'error' });        
    }
  }

  doWithdrawal = async () => {
    if(this.state.TotalBonus > 0){
      this.setState({loading:true});
      this.state.contract.events.Withdrawn((err, eventResult) => {
        console.log('eventResult',eventResult);
        console.log('err',err);
        if (!err) {
          let uerAddress = this.state.accounts;
          if(eventResult.returnValues[0] == uerAddress){
            this.fetchData();
            this.setState({loading:false});
            this.props.enqueueSnackbar("Withdrawal Successfully!",{ variant: 'success' });                
          }              
        }else{
          console.log(err);
          this.setState({loading:false});
          this.props.enqueueSnackbar('Some Network Error Occurred!',{ variant: 'error' });              
        }        
      });

      let withdrawal = await this.state.contract.methods.withdrawal().send(
        { 
          from: this.state.accounts
        }
      );
      //console.log('withdrawal',withdrawal); 
    }else{
      this.props.enqueueSnackbar('Insufficient balance!',{ variant: 'error' });
    }
         
  }

  render() {
    return (
      <Frame withHeader={true} withFooter={true}>
        {/* <div className="text-right pt_20">
          <a className="top_grad_btn account_mobile_btn" href="/account-summary">Account summary <i className="fa fa-long-arrow-right"></i></a>
        </div> */}
        {this.state.loading ? (
          <div className="loader-container">
            <span className="loader-2"> </span>
          </div>
        ) : (null)}
        <div className="pt_60 text-center">
          <div className="banner_text">
           <h1>Stake &amp; Earn!</h1>
            <h4>Decentralized  <span> Investment Platform!</span></h4>
            <p>FTMatic is a trusted place for investments using decentralized digital assets, and we help you manage your investments the hassle-free way.</p>
            <a target="_blank" style={{fontSize:18}} href="https://polygonscan.com/address/0x420eDED302517C7138F2F15D7e9F114faE971767">
              View in Contract
            </a>
            <a target="_blank" class="popup-youtube" href="video/FTMatic-Video.mp4">
              <img class="video-icon" src="img/video-icon.png" alt="" />
            </a>
          </div>
        </div>
        
        <div className="container">
          <div className="pt_50 pb_50">
            <div className="all_heading">
              <h2>Join with us now</h2>
            </div>
            <div className="row">
              <div className="col-md-8 col-lg-8 col-sm-8">
                <div className="form-group">
                  {(this.state.userInfo.checkpoint != undefined && this.state.userInfo.checkpoint > 0) ?(
                    <input className="cus_input" type="text" placeholder="Sponsor Address"
                      readOnly
                      value={this.state.user_referer}
                      onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                    />
                  ):(
                    <input className="cus_input" type="text" placeholder="Sponsor Addresss"
                      value={this.state.sponsor_address}
                      onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-4">
                <div className="form-group">
                  <button onClick={this.doJoinNow} className="grad_btn btn-block">Join Now</button>
                </div>
              </div>
            </div>
            <ul className="trx_btn">
              {defaultAmountArr.map((amount,key) => {return(
                <li key={key}>
                  <button className={this.state.amountBNB==amount?'active':'inactive'}
                    onClick={e => this.setState({amountBNB:amount})}>
                    {amount+' MATIC'}
                  </button>
                </li>
                )                              
              })}
            </ul>
          </div>

          <div className="pb_50">
            <div className="small_heading">
              <h5>ID - {this.state.YourAddress}</h5>
            </div>
            <div className="row sm_Personal_p cus_row">
              <div className="col-md-4 col-sm-4 col-6">
                <div className="Personal_Details_inner">
                  <h4>Community Level</h4>
                  <p>Upline Income</p>
                  <h5>{this.state._userUplineIncome} MATIC</h5>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-6">
                <div className="Personal_Details_inner">
                  <h4>Community Level</h4>
                  <p>Downline Income</p>
                  <h5>{this.state._userDownlineIncome} MATIC</h5>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-6">
                <div className="Personal_Details_inner">
                  <h4>Affiliate Level</h4>
                  <p>Sponsor Income</p>
                  <h5>{this.state.userInfo.referrerBonus} MATIC</h5>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-6">
                <div className="Personal_Details_inner">
                  <h4>Pool</h4>
                  <p>Pool Income 1</p>
                  <h5>{this.state.GetPoolIncome} MATIC</h5>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-12">
                <div className="Personal_Details_inner">
                  <h4>Pool</h4>
                  <p>Pool Income 2</p>
                  <h5>{this.state.GetPoolIncomeTwo} MATIC</h5>
                </div>
              </div>
            </div>
          </div>

          <div className="pb_50">
            <div className="all_heading">
              <h2>WALLET BALANCE</h2>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-12 col-sm-12">
                <div className="form-group">
                  <label className="white_label">Available MATIC</label>
                  <input className="cus_input" type="text" readOnly value={this.state.balance} />
                </div>
              </div>
              {/* <div className="col-md-6 col-lg-6 col-sm-6">
                <div className="form-group">
                  <label className="white_label">Withdrawal MATIC(Min:100 MATIC)</label>
                  <input className="cus_input" type="text" value="0" />
                </div>
              </div> */}
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-6">
                <div className="form-group">
                  <label className="white_label">Reinvestment Amount (MATIC)</label>
                  <input className="cus_input" type="text" readOnly 
                    value={parseFloat(this.state.reinvest_percentage*this.state.balance/100).toFixed(5)}                
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6">
                <div className="form-group">
                  <label className="white_label">Withdrawable Amount (MATIC)</label>
                  <input className="cus_input" type="text" readOnly
                    value={parseFloat(this.state.withdrwal_percentage*this.state.balance/100).toFixed(5)}                
                  />
                </div>
              </div>
            </div>
            <div className="form-group mb_20">
              <p className="white_label mb-0">Reinvestment percentage: <strong>{this.state.reinvest_percentage}%</strong></p>
              <p className="white_label">Withdrawable percentage: <strong>{this.state.withdrwal_percentage}% </strong></p>
            </div>
            <div className="text-center">
              <button onClick={this.doWithdrawal} className="grad_btn">Submit</button>
            </div>
            {/* <div className="text-center">
              <h5 className="top_btn Missing_transaction_text" data-toggle="collapse" data-target="#Missing_transaction_text">Missing transaction?</h5>
            </div>
            <div id="Missing_transaction_text" className="collapse">
              <div className="sm_container">
                <br />
                <div className="">
                  <div className="row">
                    <div className="col-md-8 col-lg-8 col-sm-8">
                      <div className="form-group">
                        <input className="cus_input" type="text" placeholder="Transaction Hash" />
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-4">
                      <button className="grad_btn btn-block">Submit</button>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>  */}

          </div>

          <div className="pb_50">
            <div className="all_heading">
              <h2>Personal Details</h2>
            </div>
            <div className="row cus_row">
              <div className="col-md-4 col-sm-4 col-6">
                <div className="Personal_Details_inner">
                  {/* <span className="tron_coin"><img src="img/matic-icon.png" alt="" /></span> */}
                  <h4>Total Deposit</h4>
                  <h5>{this.state.userInfo.amount}</h5>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-6">
                <div className="Personal_Details_inner">
                  {/* <span className="tron_coin"><img src="img/matic-icon.png" alt="" /></span> */}
                  <h4>Total Withdrawn</h4>
                  <h5>{this.state.userInfo.totalWithdrawn}</h5>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-12">
                <div className="Personal_Details_inner">
                  {/* <span className="tron_coin"><img src="img/matic-icon.png" alt="" /></span> */}
                  <h4>Referred By</h4>
                  <h5>{this.state.user_referer}</h5>
                </div>
              </div>
            </div>
          </div>

          <div className="pb_50">
            <div className="all_heading">
              <h2>Global Details</h2>
            </div>
            <div className="row cus_row">
              <div className="col-md-4 col-sm-6 col-6">
                <div className="Personal_Details_inner">
                  {/* <span className="tron_coin"><img src="img/matic-icon.png" alt="" /></span> */}
                  <h4>Total Community</h4>
                  <h5>{this.state.totalUsers}</h5>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-6">
                <div className="Personal_Details_inner">
                  {/* <span className="tron_coin"><img src="img/matic-icon.png" alt="" /></span> */}
                  <h4>Total Invested</h4>
                  <h5>{this.state.totalInvested}</h5>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 col-12">
                <div className="Personal_Details_inner">
                  {/* <span className="tron_coin"><img src="img/matic-icon.png" alt="" /></span> */}
                  <h4>Total Withdrawal</h4>
                  <h5>{this.state.totalWithdrawn}</h5>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-4 col-6">
                <div className="Personal_Details_inner">
                  {/* <span className="tron_coin"><img src="img/matic-icon.png" alt="" /></span> */}
                  <h4>Total Pool 1</h4>
                  <h5>{this.state.poolDepositTotal}</h5>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-6">
                <div className="Personal_Details_inner">
                  {/* <span className="tron_coin"><img src="img/matic-icon.png" alt="" /></span> */}
                  <h4>Daily Pool 1</h4>
                  <h5>{this.state.poolDeposit}</h5>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-12">
                <div className="Personal_Details_inner">
                  {/* <span className="tron_coin"><img src="img/matic-icon.png" alt="" /></span> */}
                  <h4>Pool Qualifiers 1</h4>
                  <h5>{this.state.poolQualifierCount}</h5>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-sm-4 col-6">
                <div className="Personal_Details_inner">
                  {/* <span className="tron_coin"><img src="img/matic-icon.png" alt="" /></span> */}
                  <h4>Total Pool 2</h4>
                  <h5>{this.state.poolDepositTotalTwo}</h5>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-6">
                <div className="Personal_Details_inner">
                  {/* <span className="tron_coin"><img src="img/matic-icon.png" alt="" /></span> */}
                  <h4>Daily Pool 2</h4>
                  <h5>{this.state.poolDepositTotalTwo}</h5>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-12">
                <div className="Personal_Details_inner">
                  {/* <span className="tron_coin"><img src="img/matic-icon.png" alt="" /></span> */}
                  <h4>Pool Qualifiers 2</h4>
                  <h5>{this.state.poolQualifierCountTwo}</h5>
                </div>
              </div>
            </div>

          </div>

          <div className="pb_50">
            <div className="all_heading">
              <h2>Community Level</h2>
            </div>
            <div className="table-responsive table-fixed">
              <table className="table table-bordered mb-0">
                <thead>
                  <tr>
                    <th className="col-3">Level</th>
                    <th className="col-6">User ID</th>
                    <th className="col-3">Investment</th>
                    {/* <th className="col-3">Income</th> */}
                  </tr>
                </thead>
                <tbody>
                  {this.state.communityLevels.length ? (
                    this.state.communityLevels.length>0 ? (
                      this.state.communityLevels.map(function(val, index){
                        let class_name = 'lebel_'+val.level;
                        if(val.level == 0){
                          class_name = 'current_user';
                        }
                        return (
                          <tr key={`cl${index}`} className={class_name}>
                            <td className="col-3">{val.levelText}</td>
                            <td className="col-6">{val.username}</td>
                            <td className="col-3">{val.investment} MATIC</td>
                            {/* <td className="col-3">{val.income} MATIC</td> */}
                          </tr>
                        )
                      })
                    ):(null)
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">No Data Available!</td>
                    </tr>
                  )}              
                </tbody>
              </table>
            </div>
          </div>

          <div className="pb_50">
            <div className="all_heading">
              <h2>My sponsor List</h2>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered mb-0">
                <thead>
                  <tr>
                    <th className="col-3"> Level </th>
                    <th className="col-3"> Count </th>
                    <th className="col-3"> Invest Amount </th>
                    <th className="col-3"> Amount </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.sponsorList.length ? (
                    this.state.sponsorList.length>0 ? (
                      this.state.sponsorList.map(function(val, index){
                        let class_name = 'lebel_'+val.level;
                        if(val.level == 0){
                          class_name = 'current_user';
                        }
                        return (
                          <tr key={`sl${index}`} className={class_name}>
                            <td className="col-3"> Level-{val.level} <span style={{color:'#d37bff'}}>{val.requireText?(`(${val.requireText})`):(null)}</span></td>
                            <td className="col-3">{val._noOfUser}</td>
                            <td className="col-3">{val._investment} MATIC</td>
                            <td className="col-3">{val.percentage_amount} MATIC {val.status?(`(${val.status})`):(null)}</td>
                          </tr>
                        )
                      })
                    ):(null)
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">No Data Available!</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="referal_inner pb_50 text-center">
            {/* <img className="refer_img" src="img/referal_img.png" alt="" /> */}
            <h3>Your Referral Link</h3>
            <h4 className="word-break">https://ftmatic.io/{this.state.YourAddress}</h4>
            <CopyToClipboard text={`https://ftmatic.io/${this.state.YourAddressfull}`}
              onCopy={() => this.props.enqueueSnackbar("Copied Successfully!",{ variant: 'info' })
              }>
              <button className="grad_btn">Copy Link</button>
            </CopyToClipboard>
          </div>
        </div>
        
      </Frame>
    );
  }
}

const useStyles = {
  root: {
    flexGrow: 1
  }
}

export default withRouter(withStyles(useStyles)(withSnackbar(Home)));