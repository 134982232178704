import React, { Component } from 'react';

class Footer extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <div className="container">
         
          <footer>
            <div className="">
              <img className="footer_logo" src="img/logo-text.png" alt="" />
              <hr />
              <div className="pb_20">
                <ul className="pdf_ul">
                  <li>
                    <a target="_blank" href="pdf/FTmatic.io.pdf"><i className="fa fa-download"></i> English PDF</a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/FTmatic.io_Chinese.pdf"><i className="fa fa-download"></i> Chinese PDF</a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/FTmatic.io_German.pdf"><i className="fa fa-download"></i> German PDF</a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/FTmatic.io_Hindi.pdf"><i className="fa fa-download"></i> Hindi PDF</a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/FTmatic.io_Japanese.pdf"><i className="fa fa-download"></i> Japanese PDF</a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/FTmatic.io_Korean.pdf"><i className="fa fa-download"></i> Korean PDF</a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/FTmatic.io_Tagalog.pdf"><i className="fa fa-download"></i> Tagalog PDF</a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/FTmatic.io_Vietnamese.pdf"><i className="fa fa-download"></i> Vietnamese PDF</a>
                  </li>
                </ul>
              </div>

              <p>&copy; 2021 FTMatic All Rights Reserved. </p>
            </div>
          </footer>
        </div>
      );
    }
  }

  export default Footer;